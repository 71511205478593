import { useEffect } from 'react';
import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { useAppSelector, useAppDispatch } from './hooks/useRedux';
import { getProfileAction } from './actions/auth';

import SignIn from './pages/SignIn';
import Classement from './pages/Classement';

import Loader from './components/Loader';

const Redirect = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) getProfileAction(dispatch).catch(() => { navigate('/'); });
    else navigate('/');
  }, []);

  return (
    <Loader />
  );
};

const AppRoutes = () => {
  const authReducer = useAppSelector((state: any) => state.authReducer);
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      {authReducer.user && (
        <>
          <Route path="/classement">
            <Route path=":type" element={<Classement />} />
            <Route path="" element={<Classement />} />
          </Route>
        </>
      )}
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
};

export default AppRoutes;
