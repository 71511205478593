import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DispatchType } from '../../types';
import {
  signIn,
  resetError,
} from '../../actions/auth';
import { ISignIn } from '../../types/auth.types';
import SignInForm from '../../components/SignInForm';
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
import styles from './signin.module.scss';
import logo from '../../assets/images/logoLeclerc.png';

const type = 'surgelé';

function SignIn(): JSX.Element {
  const dispatch: DispatchType = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authReducer = useAppSelector((state: any) => state.authReducer);

  useEffect(() => resetError(dispatch), [location.pathname]);

  useEffect(() => {
    if (authReducer.user?._id) navigate('/classement');
  }, [
    authReducer.user,
  ]);

  function submit(data: ISignIn) {
    signIn(dispatch, data);
  }

  return (
    <div className={styles.signin}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src={logo} alt="logo Leclerc" />
        </div>
        <h1>Scapalsace</h1>
      </div>
      <div className={`${styles.form} roundedContainer`}>
        <div className={`container ${styles.header}`}>
          <h2>Connexion</h2>
          <p>Bienvenue dans l'espace {type} du salon SCAPALSACE du 28 avril 2022</p>
        </div>
        <div className={'container'}>
          <SignInForm
            submit={submit}
            errorMessage={authReducer.error}
          />
        </div>
      </div>

    </div>
  );
}

export default SignIn;
