import React from 'react';
import styles from './error-field.module.scss';

interface IErrorField {
  message?: string,
}

const ErrorField = ({ message = '' }: IErrorField): JSX.Element => (
  <div className={styles['errors-fields']}>
    <p>{message}</p>
  </div>
);

export default ErrorField;
