import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useTransition, animated } from '@react-spring/web';
import styles from './top-ranking.module.scss';

type Item = {
  name: string,
  score: number,
};

interface ITopRanking {
  ranking: Array<Item>;
  lastUpdate: number,
}

const Title = React.memo(({ title, score }: { title: string, score: number }) => {
  const [items, setItems] = useState<string[]>([]);
  const transitions = useTransition(items, {
    from: { opacity: 0, transform: 'translate(0, 30px)' },
    enter: { opacity: 1, transform: 'translate(0, 0)' },
    leave: { opacity: 0, transform: 'translate(0, -30px)' },
    exitBeforeEnter: true,
  });

  useEffect(() => {
    setItems(() => [title]);
  }, [title]);

  return (
    <>
      {transitions((style, i) => (
        <>
          <animated.p
            style={{
              ...style,
            }}
          >
            {i}
          </animated.p>
          <animated.p
            className={styles.score}
            style={{
              ...style,
            }}
          >
            {score} pts
          </animated.p>
        </>
      ))}
    </>
  );
});

const TopRanking = forwardRef(({ ranking, lastUpdate }: ITopRanking, ref): JSX.Element => {
  const [className, setClassName] = useState('');
  const [podium, setPodium] = useState(ranking);

  useEffect(() => {
    setPodium(ranking);
  }, [ranking, lastUpdate]);

  useImperativeHandle(ref, () => ({
    toggle: async (bool: boolean): Promise<any> => {
      setClassName(bool ? 'fadeIn' : 'fadeOut');
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 1500));
    },
  }), []);

  return (
    <div className={`${styles.topranking} ${styles[className]}`}>
      <div className={styles.podium}>
        <div>
          <Title title={podium[1]?.name} score={podium[1]?.score} />
          <div className={styles.stud}>
            <span><p>2</p></span>
          </div>
        </div>
        <div>
          <Title title={podium[0]?.name} score={podium[0]?.score} />
          <div className={styles.stud}>
            <span><p>1</p></span>
          </div>
        </div>
        <div>
          <Title title={podium[2]?.name} score={podium[2]?.score} />
          <div className={styles.stud}>
            <span><p>3</p></span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default React.memo(TopRanking);
