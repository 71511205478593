/* eslint-disable no-else-return */
import React, {
  useState,
  useEffect,
} from 'react';
import { useTransition, animated } from '@react-spring/web';
import Lottie from 'lottie-react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useRedux';
import { getRankingMagasins, getRankingProviders } from '../../actions/visit';
import { logout } from '../../actions/auth';
import styles from './classement.module.scss';
import video from '../../assets/videos/leclerc_1.mp4';
import logo from '../../assets/images/logoLeclerc.png';
import leadershipTalents from '../../assets/lottie/leadership-talents.json';
import Ranking from '../../components/Ranking';

interface IType {
  type?: string | null
}

const secondsBetweenRequest = 3;
// const secondsBetweenRequest = 10;

const Classement = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [searchParams] = useSearchParams();
  let { type: defaultType }: IType = params;
  if (!['magasins', 'fournisseurs'].includes(defaultType || '')) defaultType = null;
  const [type, setType] = useState(defaultType || 'fournisseurs');
  const [subtypes, setSubtypes] = useState<string[] | null>(null);
  const [subtype, setSubtype] = useState<string | null>(null);
  const [seconds, setSeconds] = useState(0);
  const options = {
    animationData: leadershipTalents,
    loop: true,
    autoplay: true,
  };

  const transitions = useTransition(type, {
    from: { opacity: 0, transform: 'translate(0, 30px)' },
    enter: { opacity: 1, transform: 'translate(0, 0)' },
    leave: { opacity: 0, transform: 'translate(0, -30px)' },
    exitBeforeEnter: true,
  });

  const transitionsSubtypes = useTransition(subtype, {
    from: { opacity: 0, transform: 'translate(0, 30px)' },
    enter: { opacity: 1, transform: 'translate(0, 0)' },
    leave: { opacity: 0, transform: 'translate(0, -30px)' },
    exitBeforeEnter: true,
  });

  function getRanking() {
    if (defaultType === 'magasins') getRankingMagasins(dispatch);
    else if (defaultType === 'fournisseurs') getRankingProviders(dispatch);
    else {
      getRankingMagasins(dispatch);
      getRankingProviders(dispatch);
    }
  }

  function handleChangeRankingTypes() {
    // IF THERE ARE NO defaultType and subtypes IT MEANS ANIMATION
    // BETWEEN RANKING FOURNISSEURS AND MAGASINS
    if (!defaultType) {
      setType((d) => (d === 'magasins' ? 'fournisseurs' : 'magasins'));
    }

    // IF THERE ARE A defaultType AND subtypes
    // THEM SUBTYPES ARE ANIMATED
    // BETWEEN THEM
    if ((subtypes && subtypes.length > 1) && defaultType) {
      let subTypesIndex = subtypes.indexOf(subtype || '');
      subTypesIndex += 1;
      setSubtype(subtypes[subTypesIndex % subtypes.length]);
    }
  }

  // HANDLE UPDATE SCORE
  useEffect(() => {
    const timer = setTimeout(() => setSeconds((s) => s + 1), 1000);
    if (seconds === 0) {
      getRanking();
    }
    if ((seconds !== 0 && seconds % secondsBetweenRequest === 0)) {
      getRanking();
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [seconds]);

  // INIT SUBTYPES
  useEffect(() => {
    const search = Object.fromEntries(searchParams);
    const arrSubtypes = search?.subtypes?.split(',');
    if (arrSubtypes) setSubtype(arrSubtypes[0]);
    setSubtypes(arrSubtypes || []);
  }, []);

  useEffect(() => {
    const handlePressKey = (e: KeyboardEvent) => {
      if (e.key === 'q') logout(dispatch);
    };
    window.addEventListener('keydown', handlePressKey);
    return () => {
      window.removeEventListener('keydown', handlePressKey);
    };
  }, []);

  return (
    <div className={styles.classement}>
      <div className={styles.header}>
        <div>
          <p>Classement</p>
          {defaultType && (
            <>
              {(subtypes && subtypes?.length > 0) ? (
                <>
                  {transitionsSubtypes((style, d) => (
                    <>
                      <animated.div
                        className={styles.slide}
                        style={{
                          ...style,
                        }}
                      >
                        <h1>
                          {defaultType === 'magasins' ? 'Magasins' : 'Fournisseurs'}
                          {subtypes.map((s) => (
                            <React.Fragment key={`ranking-${s}`}>
                              {d === s ? ` - ${s}` : ''}
                            </React.Fragment >
                          ))}
                        </h1>
                      </animated.div>
                    </>
                  ))}
                </>
              ) : (
                <h1>
                  {defaultType === 'magasins' ? 'Magasins' : 'Fournisseurs'}
                </h1>
              )}
            </>
          )}
          {(!defaultType && type) && (
            <div className={styles.containerTitle}>
              {transitions((style, d) => (
                <>
                  {d === 'fournisseurs' && (
                    <animated.div
                      className={styles.slide}
                      style={{
                        ...style,
                      }}
                    >
                      <h1>Fournisseurs</h1>
                    </animated.div>
                  )}
                  {d === 'magasins' && (
                    <animated.div
                      className={styles.slide}
                      style={{
                        ...style,
                      }}
                    >
                      <h1>Magasins</h1>
                    </animated.div>
                  )}
                </>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.col}>
          <img className={styles.logo} src={logo} alt="logo leclerc" />
          <div className={styles.containerLottie}>
            <Lottie {...options} />
          </div>
        </div>
        <div className={styles.col}>
          {defaultType
            ? <div className={styles.containerRankings}>
              {(subtypes && subtypes?.length > 0)
                ? <>
                  {transitionsSubtypes((style, d) => ( // d === current subtype
                    <>
                      {subtypes.map((s) => (
                        <React.Fragment key={`ranking-${s}`}>
                          {d === s && (
                            <animated.div
                              className={styles.slide}
                              style={{
                                ...style,
                              }}
                            >
                              <Ranking
                                key={`ranking-${s}`}
                                type={defaultType}
                                subtype={s}
                                handleNext={handleChangeRankingTypes}
                              />
                            </animated.div>
                          )}
                        </React.Fragment >
                      ))}
                    </>
                  ))}
                </>
                : <Ranking type={defaultType} />
              }
            </div>
            : <div className={styles.containerRankings}>
              {/* TRANSITION BETWEEN TYPE FOURNISSEURS AND MAGASINS */}
              {transitions((style, d) => (
                <>
                  {['fournisseurs', 'magasins'].map((t) => (
                    <React.Fragment key={`ranking-${t}`}>
                      {d === t && (
                        <animated.div
                          className={styles.slide}
                          style={{
                            ...style,
                          }}
                        >
                          <Ranking
                            type={t}
                            handleNext={handleChangeRankingTypes}
                          />
                        </animated.div>
                      )}
                    </React.Fragment>
                  ))}
                </>
              ))}
            </div>
          }
        </div>
      </div>
      <video src={video} muted={true} loop autoPlay />
    </div >
  );
};

export default Classement;
