import React, {
  useState,
  useEffect,
  createRef,
} from 'react';
import { useTransition, animated } from '@react-spring/web';
import { withWindowSize } from 'react-fns';
import { IRank } from '../../types/visits.types';
import styles from './ranking-list.module.scss';

const ItemRank = ({ item, index, maxTop }: {
  item: {
    name: string,
    score: number,
    countVisits: number,
  },
  index: number
  maxTop: number,
}): JSX.Element => {
  const itemRef = createRef<HTMLDivElement>();
  const [hide, setHide] = useState(true);
  useEffect(() => {
    if (maxTop === 0) return;
    const bound = itemRef.current?.getBoundingClientRect();
    const boundParent = itemRef.current?.parentElement?.getBoundingClientRect();
    let limit;
    if (bound && boundParent) limit = bound.top - boundParent.top;
    if (typeof limit === 'number' && limit < maxTop) setHide(false);
  }, [maxTop]);
  return (
    <div className={styles.item} ref={itemRef} style={{ opacity: hide ? 0 : 1 }}>
      <div className={styles.index}>
        <p>{index}</p>
      </div>
      <div className={styles.provider}>
        <p>{item.name}</p>
        <p className={styles.countVisits}>{item.countVisits} visite{item.countVisits > 1 && 's'}</p>
      </div>
      <div className={styles.score}>
        <p>{item.score} pts</p>
      </div>
    </div>
  );
};

interface IAnimatedRankingList {
  ranking: IRank[],
  height: number,
  lastUpdate: number,
  handleNext?: () => void,
}

const AnimatedRankingList = ({
  ranking,
  height: windowHeight,
  lastUpdate,
  handleNext,
}: IAnimatedRankingList): JSX.Element => {
  const listRef = createRef<HTMLUListElement>();
  const [maxTop, setMaxTop] = useState(0);
  const [rows, setRows] = useState(ranking);
  const [seconds, setSeconds] = useState(0);

  const timing = 3;
  // const timing = 8;
  const itemPerPage = 5;

  function reorder(sec: number, arr: IRank[]) {
    const clone = JSON.parse(JSON.stringify(arr));
    const spliceIndex = Math.floor(sec / timing) * itemPerPage;
    const modulo = (Math.ceil((arr.length / itemPerPage)) * itemPerPage);

    // HANDLE TRIGGER NEXT RANKING IF WERE ARE
    // AT THE END OF THIS CURRENT RANKING LIST
    if (spliceIndex >= arr.length && handleNext) {
      handleNext();
    }
    clone.splice(0, spliceIndex % modulo);
    return [...clone];
  }

  useEffect(() => {
    const arr = ranking.map((d, i) => ({ ...d, num: i + 1 })).slice(3, ranking.length);
    setRows(reorder(seconds, arr));
  }, [ranking, lastUpdate]);

  useEffect(() => {
    const timer = setTimeout(() => setSeconds((state) => state + 1), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [seconds]);

  // eslint-disable-next-line no-return-assign
  let totalHeight = 0;
  const transitions = useTransition(
    // eslint-disable-next-line no-return-assign
    rows.map((data, i) => ({
      ...data,
      i,
      y: (totalHeight += 95) - 95,
    })),
    {
      key: (item: any) => item._id,
      leave: ({ y }) => ({ y: (y - 95), opacity: 0 }),
      enter: ({ y }) => ({ y, opacity: 1 }),
      update: ({ y }) => ({ y }),
    },
  );

  useEffect(() => {
    setMaxTop(listRef.current?.offsetHeight || 0);
  }, [listRef.current, ranking, windowHeight]);

  return (
    <ul ref={listRef} className={styles.listRanking} style={{ height: totalHeight }}>
      {transitions((style, item, t, index) => (
        <animated.li className={styles.row} style={{
          zIndex: ranking.length - index,
          ...style,
        }}>
          <ItemRank key={`item-rank-${item.num}`} index={item.num} maxTop={maxTop} item={item} />
        </animated.li>
      ))}
    </ul>
  );
};

const MemoAnimatedRankingList = React.memo(AnimatedRankingList);

export default withWindowSize(MemoAnimatedRankingList);
