import React from 'react';
import { Controller } from 'react-hook-form';
import styles from './input-text.module.scss';

interface IInputText {
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules?: any,
  label?: string | null,
  inline?: boolean,
  widthLabel?: string | null,
  placeholder?: string,
  defaultValue?: string,
  className?: string,
}

const InputText = ({
  name,
  control,
  rules,
  defaultValue = '',
  widthLabel = null,
  inline = false,
  label = null,
  placeholder,
  className,
}: IInputText):JSX.Element => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    defaultValue={defaultValue}
    render={({
      field: {
        // name,
        value = '',
        ref,
        onChange,
        onBlur,
      },
    }) => (
      <div className={inline ? `${className ? styles[className] : ''} ${styles['container-input']} ${styles.inline}` : `${className ? styles[className] : ''} ${styles['container-input']}`}>
        {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
        <input
          ref={ref}
          value={value}
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value)}
          className={styles.input}
          placeholder={placeholder}
        />
      </div>
    )}
  />

);

export default InputText;
