import { Action } from '../../types';
import {
  GET_RANKING_MAGASIN,
  GET_RANKING_PROVIDER,
  LOADING_VISIT,
  ERROR_VISIT,
} from '../../actions/actions';
import { IVisitsState, IRank } from '../../types/visits.types';

const initialState: IVisitsState = {
  rankingMagasins: [],
  rankingProviders: [],
  lastUpdateMagasins: Date.now(),
  lastUpdateProviders: Date.now(),
  error: null,
  isLoading: [],
};

// const data = [
//   { name: 'Petit Tonnère', type: 'fournisseur', score: 0.5 },
//   { name: 'Torrent Hurlant', type: 'fournisseur', score: 1.5 },
//   { name: 'Bernard Bernique', type: 'fournisseur', score: 2.5 },
//   { name: 'BAKASABLE 4', type: 'fournisseur', score: 0.5 },
//   { name: 'BAKASABLE 5', type: 'fournisseur', score: 1.5 },
//   { name: 'BAKASABLE 6', type: 'fournisseur', score: 2.5 },
//   { name: 'BAKASABLE 7', type: 'fournisseur', score: 0.5 },
//   { name: 'BAKASABLE 8', type: 'fournisseur', score: 1.5 },
//   { name: 'BAKASABLE 9', type: 'fournisseur', score: 2.5 },
//   { name: 'BAKASABLE 10', type: 'fournisseur', score: 0.2 },
// ]; // .sort(() => 0.5 - Math.random());
// let i = 0;

const visitsReducer = (
  state: IVisitsState = initialState,
  action: Action,
): IVisitsState => {
  let updatedState = { ...state };

  // const randomIndex = Math.floor(Math.random() * (data.length - 2));
  // const randomIndex = i === 2 ? 0 : 2;
  // const a = data[randomIndex];
  // const b = data[randomIndex + 1];

  switch (action.type) {
    case GET_RANKING_PROVIDER:
      // data[randomIndex] = b;
      // data[randomIndex + 1] = a;
      // i += 1;
      updatedState = {
        ...state,
        rankingProviders: action.payload.sort((a: IRank, b: IRank) => b.score - a.score),
        lastUpdateProviders: Date.now(),
        isLoading: state.isLoading?.filter((d) => d !== GET_RANKING_PROVIDER),
      };
      break;
    case GET_RANKING_MAGASIN:
      updatedState = {
        ...state,
        rankingMagasins: action.payload.sort((a: IRank, b: IRank) => b.score - a.score),
        lastUpdateMagasins: Date.now(),
        isLoading: state.isLoading?.filter((d) => d !== GET_RANKING_MAGASIN),
      };
      break;
    case LOADING_VISIT:
      updatedState = { ...state, isLoading: [...state.isLoading, action.payload] };
      break;
    case ERROR_VISIT:
      updatedState = { ...state, error: action.payload || null };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default visitsReducer;
