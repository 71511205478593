import { KeyboardEvent } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';

import {
  InputEmail,
  InputText,
  InputPassword,
  ErrorField,
} from '../../lib/HooksFormFields';

import styles from './signin-form.module.scss';

interface ISignInForm {
  errorMessage?: string | null,
  type?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submit(data: any): any,
}

type FormValues = {
  identifiant: string,
  email: string,
  password: string,
};

function SignInForm({
  errorMessage,
  type = 'magasin',
  submit,
}: ISignInForm): JSX.Element {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  function onSubmit(data: FormValues) {
    submit(data);
  }

  function handlePressEnter(e: KeyboardEvent<HTMLElement>): void {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={styles['signin-form']}>
      <div className={styles.form} onKeyDown={handlePressEnter}>
        <div className={styles['container-field']}>
          {type === 'magasin' && (
            <>
              <InputText
                name="email"
                control={control}
                rules={{
                  required: 'Le champs identifiant est obligatoire',
                }}
                label='Identifiant'
                placeholder="Identifiant magasin.."
              />
              {errors.email && <ErrorField message={errors.email.message} />}
            </>
          )}
          {type === 'provider' && (
            <>
              <InputEmail
                name="email"
                control={control}
                required
                label='Mail'
                placeholder="Adresse email..."
              />
              {errors.email && <ErrorField message={errors.email.message} />}
            </>
          )}
        </div>
        <div className={styles['container-field']}>
          <InputPassword
            name="password"
            control={control}
            required
            requiredMessage="Veuillez saisir votre mot de passe"
            label='Mot de passe'
            placeholder="Saissisez votre mot de passe..."
          />
          {errors.password && <ErrorField message={errors.password.message} />}
        </div>
        {(!errors.email && !errors.password) && errorMessage
          && <p className={styles['message-error']}>
            {errorMessage}
          </p>
        }
      </div>
      <div className={styles.center}>
        {type === 'magasin'
          ? <NavLink to='/fournisseur'>Accéder au login fournisseur ?</NavLink>
          : <NavLink to='/'>Accéder au login magasin ?</NavLink>
        }
      </div>

      <div className={styles.center}>
        <button onClick={handleSubmit(onSubmit)}>Connexion</button>
      </div>
    </div>
  );
}

export default SignInForm;
