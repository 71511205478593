// AUTH ACTIONS
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_GET_BADGE = 'AUTH_GET_BADGE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_LOADING_TYPE = 'LOADING_TYPE';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
// VISIT ACTIONS
export const GET_RANKING_PROVIDER = 'GET_RANKING_PROVIDER';
export const GET_RANKING_MAGASIN = 'GET_RANKING_MAGASIN';
export const LOADING_VISIT = 'LOADING_VISIT';
export const ERROR_VISIT = 'ERROR_VISIT';
