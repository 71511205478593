import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/useRedux';
import TopRanking from '../TopRanking';
import AnimatedRankingList from '../AnimatedRankingList';
// import Loader from '../Loader';
import { IRank } from '../../types/visits.types';

import styles from './ranking.module.scss';

interface IRanking {
  type?: string | null,
  subtype?: string,
  handleNext?: () => void,
}

const Ranking = ({
  type = 'magasins',
  subtype,
  handleNext,
}: IRanking): JSX.Element => {
  const [ranking, setRanking] = useState<IRank[] | null>(null);
  const visitsReducer = useAppSelector((store) => store.visitsReducer);
  const {
    rankingMagasins,
    rankingProviders,
    lastUpdateMagasins,
    lastUpdateProviders,
    // isLoading,
  } = visitsReducer;
  const lastUpdate = type === 'magasins' ? lastUpdateMagasins : lastUpdateProviders;

  useEffect(() => {
    // INIT RANKING
    if (ranking === null && (rankingMagasins.length || rankingProviders.length)) {
      let newRankings = type === 'magasins' ? rankingMagasins : rankingProviders;
      if (subtype) { newRankings = newRankings.filter((d) => d.subtype === subtype); }
      setRanking(newRankings);
    } else {
      const isEquals = (a: IRank[], b: IRank[]): boolean => (
        Array.isArray(a)
        && Array.isArray(b)
        && a.length === b.length
        && a.every((val, index) => JSON.stringify(val) === JSON.stringify(b[index]))
      );

      let newRankings = type === 'magasins' ? rankingMagasins : rankingProviders;
      if (subtype) { newRankings = newRankings.filter((d) => d.subtype === subtype); }
      const testingEqual = isEquals(ranking || [], newRankings);
      if (!testingEqual) setRanking(newRankings);
    }
  }, [rankingMagasins, rankingProviders]);

  return (
    <div className={styles.ranking}>
      {(ranking && ranking.length) && (
        <>
          <TopRanking lastUpdate={lastUpdate} ranking={ranking} />
          <AnimatedRankingList
            lastUpdate={lastUpdate}
            ranking={ranking}
            handleNext={handleNext}
            height={0}
          />
        </>
      )}
      {/*
        ((ranking && !ranking.length) && isLoading) && <Loader size={60} />
      */}
    </div>
  );
};

export default Ranking;
