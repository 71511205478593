/* eslint-disable import/prefer-default-export */
import { getData } from '.';
import { DispatchType } from '../types';
import {
  LOADING_VISIT,
  GET_RANKING_PROVIDER,
  GET_RANKING_MAGASIN,
  ERROR_VISIT,
} from './actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRankingMagasins = async (dispatch: DispatchType): Promise<any> => {
  const url = '/visit/ranking/magasin';
  dispatch({
    type: LOADING_VISIT,
    payload: GET_RANKING_MAGASIN,
  });
  const response = await getData(ERROR_VISIT, url, dispatch, true);
  if (response.data.companies) {
    dispatch({
      type: GET_RANKING_MAGASIN,
      payload: response.data.companies,
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRankingProviders = async (dispatch: DispatchType): Promise<any> => {
  const url = '/visit/ranking/fournisseur';
  dispatch({
    type: LOADING_VISIT,
    payload: GET_RANKING_PROVIDER,
  });
  const response = await getData(ERROR_VISIT, url, dispatch, true);
  if (response.data.companies) {
    dispatch({
      type: GET_RANKING_PROVIDER,
      payload: response.data.companies,
    });
  }
};
